import { Text } from '@chakra-ui/react'
import React from 'react'

const RegisterComplete = () => {
  return (
    <Text
      fontSize={{
        md: 'md',
      }}
      align="center"
      mt="20"
    >
      Thank you for registering! We'll let you know when we launch.
    </Text>
  )
}

export default RegisterComplete
