import { Box, Center, Flex, Heading, Img, Text } from '@chakra-ui/react'
import React from 'react'
import RegisterForm from './RegisterForm'

export const App = () => {
  return (
    <Box
      bg="gray.800"
      as="section"
      minH="140px"
      position="relative"
      mt={{
        base: 2,
        md: 16,
      }}
      ml={{
        base: 2,
        md: 16,
      }}
      mr={{
        base: 2,
        md: 16,
      }}
      rounded="2xl"
      boxShadow="2xl"
    >
      <Box py="32" position="relative" zIndex={1}>
        <Box
          maxW={{
            base: 'xl',
            md: '7xl',
          }}
          mx="auto"
          px={{
            base: '8',
            md: '24',
          }}
          color="white"
        >
          <Box>
            <Center>
              <Heading
                align="center"
                as="h1"
                fontSize={{
                  base: '4xl',
                  md: '6xl',
                }}
                fontWeight="extrabold"
                textColor="yellow.400"
              >
                NFTs to the <br /> N<sup>th</sup> dimension
              </Heading>
            </Center>
            <Text
              fontSize={{
                base: 'md',
                md: '2xl',
              }}
              align="center"
              mt="10"
            >
              Get ready for KRFTS - the home for Metaverse ready art, fashion
              and vehicles. Our unique crafting mechanism allows you to combine
              any number of existing NFTs to create something new and unique.
            </Text>

            <RegisterForm />
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
        rounded="2xl"
      >
        <Box position="relative" w="full" h="full">
          <Img
            src="https://images.unsplash.com/photo-1614432279462-8f3617da36d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="Main Image"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.600" />
        </Box>
      </Flex>
    </Box>
  )
}

export default App
