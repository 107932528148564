import emailjs from '@emailjs/browser'
import {
  Center,
  Button,
  Stack,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import RegisterComplete from './RegisterComplete'

const RegisterForm = () => {
  const [isSending, setIsSending] = useState()
  const [isRegistered, setIsRegistered] = useState()

  const {
    register,
    handleSubmit,
    formState,
    formState: {},
  } = useForm()

  const sendEmail = (data) => {
    setIsSending(true)

    const templateParams = {
      user_email: data.email,
    }

    emailjs
      .send(
        'krfts_contact_service',
        'krfts_registration',
        templateParams,
        'N5XgYHg25PssfIQtC',
      )
      .then(
        (result) => {
          setIsSending(false)
          setIsRegistered(true)
        },
        (error) => {
          //console.log(error.text)
        },
      )
  }

  if (isRegistered) return <RegisterComplete />

  return (
    <form onSubmit={handleSubmit(sendEmail)}>
      <Stack
        direction={{
          base: 'column',
          md: 'column',
        }}
        mt="10"
        spacing="4"
      >
        <FormControl>
          <Center>
            <FormLabel
              htmlFor="email"
              fontSize={{
                base: 'sm',
                md: 'md',
              }}
            >
              Get notified when we go live
            </FormLabel>
          </Center>
          <Center>
            <Input
              textColor="black"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please provide a valid email address',
                },
              })}
              id="email"
              type="email"
              maxW={80}
              placeholder="Enter your email"
              bgColor="white"
            />
          </Center>

          <Center>
            <FormHelperText>We'll never share your email</FormHelperText>
          </Center>
        </FormControl>

        <Center>
          <Button
            type="submit"
            maxW={40}
            mt={10}
            href="#"
            colorScheme="yellow"
            px="8"
            rounded="full"
            size="lg"
            fontSize="md"
            fontWeight="bold"
            isLoading={isSending}
            loadingText="Registering"
          >
            Register
          </Button>
        </Center>
      </Stack>
    </form>
  )
}

export default RegisterForm
