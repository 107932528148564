import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import '@fontsource/jost'
import '@fontsource/ubuntu'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const theme = extendTheme({
  shadows: {
    outlineYellow: '0 0 0 3px #ECC94B',
  },
  fonts: {
    heading: 'ubuntu',
    body: 'jost',
  },
  styles: {
    global: {
      canvas: {
        width: '100%',
        height: '100%',
      },
    },
  },

  initialColorMode: 'dark',
  useSystemColorMode: false,
})

const firebaseConfig = {
  apiKey: 'AIzaSyCuuzTG-BEqboJuyZDTiYg2Wr9Smo1aUAQ',
  authDomain: 'krfts-landing.firebaseapp.com',
  projectId: 'krfts-landing',
  storageBucket: 'krfts-landing.appspot.com',
  messagingSenderId: '973184750202',
  appId: '1:973184750202:web:7f0cfaa393ad69eebb21c3',
  measurementId: 'G-32VKYQ2CW5',
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
)
